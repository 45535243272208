import React from 'react';
import { graphql } from 'gatsby';

import MainLayout from 'src/layout/MainLayout';
import BackgroundImage from 'gatsby-background-image';

const BlogPost = ({ data }) => {
  const blogPost = data.wordpressPost;
  return (
    <MainLayout title={blogPost.title}>
      {blogPost.featured_media && (
        <BackgroundImage
          fluid={blogPost.featured_media.localFile.childImageSharp.fluid}
          className="featured-blog-post-image"
        />
      )}
      <section className="blog-post">
        <div className="text-center">
          <time className="inline-block mb-2">{blogPost.date}</time>
          <h1>{blogPost.title}</h1>
        </div>
        <div dangerouslySetInnerHTML={{ __html: blogPost.content }}></div>
      </section>
    </MainLayout>
  );
};

export default BlogPost;

export const query = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxHeight: 1080) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              presentationWidth
            }
          }
        }
      }
      date(formatString: "DD.MM.YYYY")
      title
      content
    }
  }
`;
